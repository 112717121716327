$line-color: #e9e9e9;
$shadow-color: rgba(0, 0, 0, 0.15);
$bottom-bar-bg-color: #262626;
$bottom-bar-line-color: #000;
$template-bg-color: #001529;
$template-bg-color-light: #ececec;
$template-nav-bg-color: #fff;
$template-text-color: #ccc;
$template-text-color-light: #fff;
$template-text-color-black: #73777b;
$template-text-title-color: #999;
$template-text-color-light: #fff;
$template-footer-text-color: #999;
$animate-duration: 0.45s;
$text-color: #1d4449;
$text-color2: #697b8c;
$primary-color: #8eaeba;
