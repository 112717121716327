@import '../variables.scss';

#wrap-qr-generate {
  margin-top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .input-wrapper {
    width: 100%;
  }
  button.ant-btn-primary {
    background-color: $primary-color;
  }
}
