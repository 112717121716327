@import "./news-template/header.scss";

// Utils
@import "./news-template/post/highlight-post.scss";
@import "./news-template/qrcode/qrcode.scss";

body {
  word-wrap: break-word;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-size: 13px;
  overflow-y: scroll;
  transition: background-color 0.2s;
  -webkit-font-smoothing: antialiased;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

main {
  height: 100%;
  transition: margin-left 0.2s;
  position: relative;
  box-sizing: border-box;
}

.home-title {
  line-height: 55px;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pull-right {
  float: right !important;
}

@media screen and (max-width: 767px) {
  main {
    padding: 65px 15px 20px 15px;
  }
}

/** TinyMCE**/
.tox-tinymce {
  border: none !important;
}

/** Markdown **/
.markdown-wrapper {
  img {
    max-width: 100%;
    height: auto;
  }

  svg {
    max-height: 100%;
  }

  a {
    color: #07c;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.875rem;
  }

  h6 {
    font-size: 0.75rem;
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  ul {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  code,
  pre,
  samp {
    font-family:
      Roboto Mono,
      Source Code Pro,
      Menlo,
      Consolas,
      Liberation Mono,
      monospace;
  }

  code,
  samp {
    padding: 0.125em;
  }

  code,
  pre,
  samp {
    font-size: 87.5%;
  }

  pre {
    overflow: scroll;
  }

  blockquote {
    font-size: 1.25rem;
    font-style: italic;
    margin-left: 0;
  }

  hr {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ccc;
  }
}

.polka {
  background-image: radial-gradient(
    var(--color-on-surface) 0.7px,
    var(--color-surface) 1px
  );
  background-size: 14px 14px;
}

.hide-radio .ant-radio-group label span.ant-radio {
  display: none !important;
}
.hide-radio .ant-radio-wrapper {
  margin-inline-end: 0;
}

.hide-radio .ant-radio-wrapper span.ant-radio + * {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.hide-radio .ant-radio-wrapper:has(> .ant-radio-checked) img {
  border: 1px solid black;
  padding: 1px;
}

.polka .ant-form-vertical {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

.custom-form.ant-form-vertical {
  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
}

.wp-block-code,
pre.bg-muted {
  background-color: #18191b !important;
}
